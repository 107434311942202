const billiGraphs = [
    {
        id: 1,
        title: '23 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 70, et: 130,},
            {daysFromBirth: 2, pt: 100, et: 180,},
            {daysFromBirth: 3, pt: 130, et: 230,},
            {daysFromBirth: 4, pt: 130, et: 230,},
            {daysFromBirth: 5, pt: 130, et: 230,},
            {daysFromBirth: 6, pt: 130, et: 230,},
            {daysFromBirth: 7, pt: 130, et: 230,},
            {daysFromBirth: 8, pt: 130, et: 230,},
            {daysFromBirth: 9, pt: 130, et: 230,},
            {daysFromBirth: 10, pt: 130, et: 230,},
            {daysFromBirth: 11, pt: 130, et: 230,},
            {daysFromBirth: 12, pt: 130, et: 230,},
            {daysFromBirth: 13, pt: 130, et: 230,},
            {daysFromBirth: 14, pt: 130, et: 230,},
        ]
    },        
    {
        id: 2,
        title: '24 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 73, et: 133,},
            {daysFromBirth: 2, pt: 108, et: 188,},
            {daysFromBirth: 3, pt: 140, et: 240,},
            {daysFromBirth: 4, pt: 140, et: 240,},
            {daysFromBirth: 5, pt: 140, et: 240,},
            {daysFromBirth: 6, pt: 140, et: 240,},
            {daysFromBirth: 7, pt: 140, et: 240,},
            {daysFromBirth: 8, pt: 140, et: 240,},
            {daysFromBirth: 9, pt: 140, et: 240,},
            {daysFromBirth: 10, pt: 140, et: 240,},
            {daysFromBirth: 11, pt: 140, et: 240,},
            {daysFromBirth: 12, pt: 140, et: 240,},
            {daysFromBirth: 13, pt: 140, et: 240,},
            {daysFromBirth: 14, pt: 140, et: 240,},
        ]
    },
    {
        id: 3,
        title: '25 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 78, et: 139,},
            {daysFromBirth: 2, pt: 110, et: 190,},
            {daysFromBirth: 3, pt: 150, et: 250,},
            {daysFromBirth: 4, pt: 150, et: 250,},
            {daysFromBirth: 5, pt: 150, et: 250,},
            {daysFromBirth: 6, pt: 150, et: 250,},
            {daysFromBirth: 7, pt: 150, et: 250,},
            {daysFromBirth: 8, pt: 150, et: 250,},
            {daysFromBirth: 9, pt: 150, et: 250,},
            {daysFromBirth: 10, pt: 150, et: 250,},
            {daysFromBirth: 11, pt: 150, et: 250,},
            {daysFromBirth: 12, pt: 150, et: 250,},
            {daysFromBirth: 13, pt: 150, et: 250,},
            {daysFromBirth: 14, pt: 150, et: 250,},
        ]
    },
    {
        id: 4,
        title: '26 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 80, et: 140,},
            {daysFromBirth: 2, pt: 120, et: 200,},
            {daysFromBirth: 3, pt: 160, et: 260,},
            {daysFromBirth: 4, pt: 160, et: 260,},
            {daysFromBirth: 5, pt: 160, et: 260,},
            {daysFromBirth: 6, pt: 160, et: 260,},
            {daysFromBirth: 7, pt: 160, et: 260,},
            {daysFromBirth: 8, pt: 160, et: 260,},
            {daysFromBirth: 9, pt: 160, et: 260,},
            {daysFromBirth: 10, pt: 160, et: 260,},
            {daysFromBirth: 11, pt: 160, et: 260,},
            {daysFromBirth: 12, pt: 160, et: 260,},
            {daysFromBirth: 13, pt: 160, et: 260,},
            {daysFromBirth: 14, pt: 160, et: 260,},
        ]
    },
    {
        id: 5,
        title: '27 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 83, et: 143,},
            {daysFromBirth: 2, pt: 128, et: 205,},
            {daysFromBirth: 3, pt: 170, et: 270,},
            {daysFromBirth: 4, pt: 170, et: 270,},
            {daysFromBirth: 5, pt: 170, et: 270,},
            {daysFromBirth: 6, pt: 170, et: 270,},
            {daysFromBirth: 7, pt: 170, et: 270,},
            {daysFromBirth: 8, pt: 170, et: 270,},
            {daysFromBirth: 9, pt: 170, et: 270,},
            {daysFromBirth: 10, pt: 170, et: 270,},
            {daysFromBirth: 11, pt: 170, et: 270,},
            {daysFromBirth: 12, pt: 170, et: 270,},
            {daysFromBirth: 13, pt: 170, et: 270,},
            {daysFromBirth: 14, pt: 170, et: 270,},
        ]
    },
    {
        id: 6,
        title: '28 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 90, et: 150,},
            {daysFromBirth: 2, pt: 133, et: 212,},
            {daysFromBirth: 3, pt: 180, et: 280,},
            {daysFromBirth: 4, pt: 180, et: 280,},
            {daysFromBirth: 5, pt: 180, et: 280,},
            {daysFromBirth: 6, pt: 180, et: 280,},
            {daysFromBirth: 7, pt: 180, et: 280,},
            {daysFromBirth: 8, pt: 180, et: 280,},
            {daysFromBirth: 9, pt: 180, et: 280,},
            {daysFromBirth: 10, pt: 180, et: 280,},
            {daysFromBirth: 11, pt: 180, et: 280,},
            {daysFromBirth: 12, pt: 180, et: 280,},
            {daysFromBirth: 13, pt: 180, et: 280,},
            {daysFromBirth: 14, pt: 180, et: 280,},
        ]
    },
    {
        id: 7,
        title: '29 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 90, et: 150,},
            {daysFromBirth: 2, pt: 140, et: 220,},
            {daysFromBirth: 3, pt: 190, et: 290,},
            {daysFromBirth: 4, pt: 190, et: 290,},
            {daysFromBirth: 5, pt: 190, et: 290,},
            {daysFromBirth: 6, pt: 190, et: 290,},
            {daysFromBirth: 7, pt: 190, et: 290,},
            {daysFromBirth: 8, pt: 190, et: 290,},
            {daysFromBirth: 9, pt: 190, et: 290,},
            {daysFromBirth: 10, pt: 190, et: 290,},
            {daysFromBirth: 11, pt: 190, et: 290,},
            {daysFromBirth: 12, pt: 190, et: 290,},
            {daysFromBirth: 13, pt: 190, et: 290,},
            {daysFromBirth: 14, pt: 190, et: 290,},
        ]
    },
    {
        id: 8,
        title: '30 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 93, et: 155,},
            {daysFromBirth: 2, pt: 149, et: 228,},
            {daysFromBirth: 3, pt: 200, et: 300,},
            {daysFromBirth: 4, pt: 200, et: 300,},
            {daysFromBirth: 5, pt: 200, et: 300,},
            {daysFromBirth: 6, pt: 200, et: 300,},
            {daysFromBirth: 7, pt: 200, et: 300,},
            {daysFromBirth: 8, pt: 200, et: 300,},
            {daysFromBirth: 9, pt: 200, et: 300,},
            {daysFromBirth: 10, pt: 200, et: 300,},
            {daysFromBirth: 11, pt: 200, et: 300,},
            {daysFromBirth: 12, pt: 200, et: 300,},
            {daysFromBirth: 13, pt: 200, et: 300,},
            {daysFromBirth: 14, pt: 200, et: 300,},
        ]
    },
    {
        id: 9,
        title: '31 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 99, et: 159,},
            {daysFromBirth: 2, pt: 151, et: 231,},
            {daysFromBirth: 3, pt: 210, et: 310,},
            {daysFromBirth: 4, pt: 210, et: 310,},
            {daysFromBirth: 5, pt: 210, et: 310,},
            {daysFromBirth: 6, pt: 210, et: 310,},
            {daysFromBirth: 7, pt: 210, et: 310,},
            {daysFromBirth: 8, pt: 210, et: 310,},
            {daysFromBirth: 9, pt: 210, et: 310,},
            {daysFromBirth: 10, pt: 210, et: 310,},
            {daysFromBirth: 11, pt: 210, et: 310,},
            {daysFromBirth: 12, pt: 210, et: 310,},
            {daysFromBirth: 13, pt: 210, et: 310,},
            {daysFromBirth: 14, pt: 210, et: 310,},
        ]
    },
    {
        id: 10,
        title: '32 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 100, et: 160,},
            {daysFromBirth: 2, pt: 160, et: 240,},
            {daysFromBirth: 3, pt: 220, et: 320,},
            {daysFromBirth: 4, pt: 220, et: 320,},
            {daysFromBirth: 5, pt: 220, et: 320,},
            {daysFromBirth: 6, pt: 220, et: 320,},
            {daysFromBirth: 7, pt: 220, et: 320,},
            {daysFromBirth: 8, pt: 220, et: 320,},
            {daysFromBirth: 9, pt: 220, et: 320,},
            {daysFromBirth: 10, pt: 220, et: 320,},
            {daysFromBirth: 11, pt: 220, et: 320,},
            {daysFromBirth: 12, pt: 220, et: 320,},
            {daysFromBirth: 13, pt: 220, et: 320,},
            {daysFromBirth: 14, pt: 220, et: 320,},
        ]
    },
    {
        id: 11,
        title: '33 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 103, et: 163,},
            {daysFromBirth: 2, pt: 169, et: 249,},
            {daysFromBirth: 3, pt: 230, et: 330,},
            {daysFromBirth: 4, pt: 230, et: 330,},
            {daysFromBirth: 5, pt: 230, et: 330,},
            {daysFromBirth: 6, pt: 230, et: 330,},
            {daysFromBirth: 7, pt: 230, et: 330,},
            {daysFromBirth: 8, pt: 230, et: 330,},
            {daysFromBirth: 9, pt: 230, et: 330,},
            {daysFromBirth: 10, pt: 230, et: 330,},
            {daysFromBirth: 11, pt: 230, et: 330,},
            {daysFromBirth: 12, pt: 230, et: 330,},
            {daysFromBirth: 13, pt: 230, et: 330,},
            {daysFromBirth: 14, pt: 230, et: 330,},
        ]
    },
    {
        id: 12,
        title: '34 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 109, et: 169,},
            {daysFromBirth: 2, pt: 173, et: 251,},
            {daysFromBirth: 3, pt: 240, et: 340,},
            {daysFromBirth: 4, pt: 240, et: 340,},
            {daysFromBirth: 5, pt: 240, et: 340,},
            {daysFromBirth: 6, pt: 240, et: 340,},
            {daysFromBirth: 7, pt: 240, et: 340,},
            {daysFromBirth: 8, pt: 240, et: 340,},
            {daysFromBirth: 9, pt: 240, et: 340,},
            {daysFromBirth: 10, pt: 240, et: 340,},
            {daysFromBirth: 11, pt: 240, et: 340,},
            {daysFromBirth: 12, pt: 240, et: 340,},
            {daysFromBirth: 13, pt: 240, et: 340,},
            {daysFromBirth: 14, pt: 240, et: 340,},
        ]
    },
    {
        id: 13,
        title: '35 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 110, et: 170,},
            {daysFromBirth: 2, pt: 180, et: 260,},
            {daysFromBirth: 3, pt: 250, et: 350,},
            {daysFromBirth: 4, pt: 250, et: 350,},
            {daysFromBirth: 5, pt: 250, et: 350,},
            {daysFromBirth: 6, pt: 250, et: 350,},
            {daysFromBirth: 7, pt: 250, et: 350,},
            {daysFromBirth: 8, pt: 250, et: 350,},
            {daysFromBirth: 9, pt: 250, et: 350,},
            {daysFromBirth: 10, pt: 250, et: 350,},
            {daysFromBirth: 11, pt: 250, et: 350,},
            {daysFromBirth: 12, pt: 250, et: 350,},
            {daysFromBirth: 13, pt: 250, et: 350,},
            {daysFromBirth: 14, pt: 250, et: 350,},
        ]
    },
    {
        id: 14,
        title: '36 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 115, et: 175,},
            {daysFromBirth: 2, pt: 185, et: 265,},
            {daysFromBirth: 3, pt: 260, et: 360,},
            {daysFromBirth: 4, pt: 260, et: 360,},
            {daysFromBirth: 5, pt: 260, et: 360,},
            {daysFromBirth: 6, pt: 260, et: 360,},
            {daysFromBirth: 7, pt: 260, et: 360,},
            {daysFromBirth: 8, pt: 260, et: 360,},
            {daysFromBirth: 9, pt: 260, et: 360,},
            {daysFromBirth: 10, pt: 260, et: 360,},
            {daysFromBirth: 11, pt: 260, et: 360,},
            {daysFromBirth: 12, pt: 260, et: 360,},
            {daysFromBirth: 13, pt: 260, et: 360,},
            {daysFromBirth: 14, pt: 260, et: 360,},
        ]
    },
    {
        id: 15,
        title: '37 Semanas',
        data: [
            {daysFromBirth: 0, pt: 40, et: 80,},
            {daysFromBirth: 1, pt: 120, et: 179,},
            {daysFromBirth: 2, pt: 190, et: 273,},
            {daysFromBirth: 3, pt: 270, et: 370,},
            {daysFromBirth: 4, pt: 270, et: 370,},
            {daysFromBirth: 5, pt: 270, et: 370,},
            {daysFromBirth: 6, pt: 270, et: 370,},
            {daysFromBirth: 7, pt: 270, et: 370,},
            {daysFromBirth: 8, pt: 270, et: 370,},
            {daysFromBirth: 9, pt: 270, et: 370,},
            {daysFromBirth: 10, pt: 270, et: 370,},
            {daysFromBirth: 11, pt: 270, et: 370,},
            {daysFromBirth: 12, pt: 270, et: 370,},
            {daysFromBirth: 13, pt: 270, et: 370,},
            {daysFromBirth: 14, pt: 270, et: 370,},
        ]
    },
    {
        id: 16,
        title: '38 Semanas',
        data: [
            {daysFromBirth: 0, pt: 100, et: 100,},
            {daysFromBirth: 1, pt: 200, et: 295,},
            {daysFromBirth: 2, pt: 250, et: 450,},
            {daysFromBirth: 3, pt: 300, et: 450,},
            {daysFromBirth: 4, pt: 350, et: 450,},
            {daysFromBirth: 5, pt: 350, et: 450,},
            {daysFromBirth: 6, pt: 350, et: 450,},
            {daysFromBirth: 7, pt: 350, et: 450,},
            {daysFromBirth: 8, pt: 350, et: 450,},
            {daysFromBirth: 9, pt: 350, et: 450,},
            {daysFromBirth: 10, pt: 350, et: 450,},
            {daysFromBirth: 11, pt: 350, et: 450,},
            {daysFromBirth: 12, pt: 350, et: 450,},
            {daysFromBirth: 13, pt: 350, et: 450,},
            {daysFromBirth: 14, pt: 350, et: 450,},
        ]
    },
];

export default billiGraphs;